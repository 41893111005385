require('./bootstrap');

const { createApp } = require('vue');
const axios = require('axios');
const { isMobile } = require('./isMobile');

import vSelect from 'vue-select';

const searchApp = createApp({
    data() {
        return {
            term: '',
            city: '',
            showDropdown: false,
            timeout: null,
            loading: false,
            subcategories: [],
            services: [], // atividades
            companies: [],
            isRedirect: false
        }
    },
    created() {

        let searchForm = document.querySelector('.search-form');

        document
        .querySelector('main')
        .addEventListener('click', (ev) => {
            if ( ev.target.isEqualNode( searchForm ) || !ev.target.closest('.search-form') ) {
                this.showDropdown = false;
            }
        })
    },
    methods: {
        onInputEvent($event)
        {
            if ( $event ) {
                this.term = $event.target.value;
            }
            
            this.debounce( () => {
                this.showDropdown = true;
                if (!this.term) return;
                
                this.search();
            }, 200 );
        },
        onFocus(e) {
            this.debounce( () => {
                if ( this.subcategories.length || this.services.length || this.companies.length ) {
                    this.showDropdown = true;
                }
            }, this.term ? 0 : 400 );
        },
        search() {
            if ('' == this.term) return;
            
            this.loading = true;

            axios
            .post( window.SEARCH_API_URL, { term: this.term, city: this.city || '', limit: isMobile() ? 5 : 12 })
            .then( success => {
                this.subcategories = success.data['subcategories'];
                this.services = success.data['services'];
                this.companies = success.data['companies'];
            })
            .catch( err => {
                
            })
            .finally( ev => {
                this.loading = false;
            })
        },
        searchRedirect(url) {
            if ( !this.term ) return;
            this.isRedirect = true;
            
            return document.location.href = url.replace('__term', this.term).replace('__city', this.city);
        },
        debounce( callback, delay = 1000) {
            if ( this.timeout ) clearTimeout( this.timeout );

            this.timeout = setTimeout( callback, delay );
        },
        onCitySelect($event) {
            this.city = $event;

            this.debounce( () => {
                if (!this.term) return;
                
                this.showDropdown = true;
                this.search();
            }, 400 );
        }
    }
})

searchApp.component('v-select', vSelect );

searchApp.mount('#search');